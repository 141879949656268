import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { ConfigManager } = eurekaMgrs;
const { getConfig, getLanguage } = ConfigManager;
const { tenantId, id } = getConfig('CurrentUser') || {};
const { axiosCreate } = eureka.HttpWrapper;
const headers = {
    'X-Tenant-ID': tenantId,
    'X-User-ID': id,
    'Content-Type': 'application/json',
    'Accept-Language': getLanguage() === undefined ? 'en' : getLanguage(),
  };

export const basicSetupApi = axiosCreate(
    {
      headers,
      baseURL: `/api/crp-generic-biz-config/business/v1/basic-setup`,
      timeout: 10000,
    },
    0,
  ).instance;

export const editBasicSetupData = (settings) => basicSetupApi.put('', settings);